import React from "react"

import Box from "@material-ui/core/Box"
import Container from "@ecom/ui/components/Container"
import PageStatus from "@ecom/ui/components/PageStatus"
import { graphql, PageProps } from "gatsby"
import { LayoutBase } from "../../components/Layout/base"
import { PageData } from "../../interfaces/pageProps"
import { getPageData } from "../../helpers/getPageData"
import { UnicHeader } from "../../components/Headers/UnicHeader"
import { WhiteHalva } from "../../components/Headers/UnicHeader/Logo/whiteHalva"
import { FooterProducts } from "../../components/Footer/FooterProducts/FooterProducts"

export default function SuccessPage({ data }: PageProps<PageData>) {
  const { ligal } = getPageData(data)
  return (
    <div style={{ height: "100%", backgroundColor: "#0B0B0B" }}>
      <LayoutBase>
        <UnicHeader Logo={WhiteHalva} />
        <section className="form-outer">
          <Container>
            <Box display="flex" justifyContent="center">
              <PageStatus success />
            </Box>
          </Container>
        </section>
        <FooterProducts>
          {ligal.shortText && (
            <span dangerouslySetInnerHTML={{ __html: ligal.shortText }} id="ligal" />
          )}
        </FooterProducts>
      </LayoutBase>
    </div>
  )
}

export const query = graphql`
  query {
    pageDataJson(pageName: { eq: "b2b" }) {
      seoData {
        title
        description
      }
    }
    admin {
      page(url: "https://go.halvacard.ru/referralhub/") {
        ligal {
          text
        }
      }
    }
  }
`
